.root {
  @apply items-center font-medium fixed bottom-2 md:right-24 w-96 rounded-lg bg-white py-6 right-2;

  box-shadow: rgba(0, 0, 0, 0.12) 0 0 15px 3px;
  max-width: 100vw;
  z-index: 9999;
}

.header {
  @apply relative z-40 justify-between flex px-6;
}

.close {
  @apply w-2 pt-1;
}

.actions {
  @apply flex justify-evenly pt-4 px-1;
}
