@use '@assets/mixins/overlay-top-arrow' as overlay;

.root {
  @include overlay.overlay-arrow;
  @apply py-m px-xl;

  right: -51px;

  @screen md {
    right: -51px;
  }

  &::after {
    right: 38px;
  }
}

.iconContainer {
  @apply flex items-center justify-center;
}
