.buttonContainer {
  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.button {
  opacity: 1;

  &.paused {
    opacity: 0;
  }
}

.thumbnailOverlay {
  @apply absolute inset-y-0 inset-x-0 m-auto;

  width: 35px;
  height: 35px;
}
