@use '@assets/mixins/typography';

.root {
  @apply fixed top-0 left-0 w-screen h-screen bg-white py-l px-xl flex flex-col;

  z-index: 9999;

  .closeButton {
    @apply border-disabled bg-transparent;
  }

  .countryList {
    @apply uppercase;
    @include typography.text('text-4');

    color: var(--text-secondary);
    margin-bottom: 55px;

    .countryItem {
      @apply flex items-center;

      cursor: default;
      margin-bottom: 30px;

      img {
        @apply inline;
      }
    }

    .langLink {
      @apply uppercase;

      color: var(--purple);
      cursor: pointer;
    }
  }

  .titleContainer {
    @apply flex items-center justify-between;

    padding-bottom: 35px;
  }

  .bodyContainer {
    @apply justify-center overflow-y-auto;
  }

  @screen lg {
    padding: 65px calc((100% - 800px) / 2) 0;

    .title {
      @apply flex-auto text-center;
    }

    .titleContainer {
      padding-bottom: 65px;
    }

    .countryList {
      @apply grid;

      grid-gap: 10px;
      grid-auto-flow: column;
      margin-bottom: 65px;
    }
  }
}

.modal[role='dialog'] {
  padding: 35px 20px 20px;

  > button {
    margin-top: 10px;
  }

  @screen lg {
    padding: 48px 36px;
  }
}

.dialogConfirm {
  @apply py-0 w-auto;

  margin-left: 2%;
  min-height: 40px;

  @screen sm {
    @apply ml-4;
  }
}
