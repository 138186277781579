@use '@assets/mixins/typography';

.root {
  @apply relative;
}

.button {
  @apply flex items-center justify-center;
}

.button:hover {
  @apply border-accents-4 shadow-sm;
}

.text {
  @include typography.text('medium');

  @screen md {
    font-size: 13px;
    line-height: 17px;
  }
}

.dropdownMenu {
  @apply fixed right-0 top-0 mt-2 origin-top-right outline-none bg-primary z-40 w-full h-full;

  @screen lg {
    @apply absolute border border-accents-1 shadow-lg w-56 h-auto mt-0;

    top: 34px;
  }
}

@screen md {
  .closeButton {
    @apply hidden;
  }
}

.item {
  @apply flex cursor-pointer px-6 py-3 transition ease-in-out duration-150 text-primary leading-6 font-medium items-center w-full;

  text-transform: capitalize;
}

.item:hover {
  @apply bg-accents-1;
}

.icon {
  transform: rotate(180deg);
}

.mobileSecondaryLink {
  @apply flex flex-row items-center space-x-2;

  .mobileSecondaryLinkIcon {
    @apply text-base lg:hidden text-inherit;
  }
}
