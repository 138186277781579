@mixin animation() {
  height: 100%;
  background-image: linear-gradient(270deg, var(--accents-0), var(--accents-1), var(--accents-1), var(--accents-0));
  background-size: 200% 100%;
  animation: loading-animation 1.5s linear infinite !important;

  @keyframes loading-animation {
    0% {
      background-position: 200% 0;
    }

    100% {
      background-position: -200% 0;
    }
  }
}
