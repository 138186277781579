@mixin headings {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @content;
  }
}

@mixin texts {
  span,
  p {
    @content;
  }
}

@mixin all-typography {
  @include headings {
    @content;
  }

  @include texts {
    @content;
  }
}
