@mixin u-hover($color: var(--pitch-black), $height: 1px) {
  @apply relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: $height;
    left: 0;
    bottom: 0;
    background-color: $color;
    transition: all 0.45s cubic-bezier(0.34, 0.37, 0.055, 1.2);
  }

  &:hover {
    &::before,
    &::after {
      width: 100%;
    }
  }
}
