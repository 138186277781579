@use '@assets/mixins/typography';
@use '@assets/mixins/input' as i;

.root {
  @apply relative;
  @include typography.text('text-3');
}

.label {
  @include i.label;
}

.focusedLabel {
  @include i.label(focused);
}

.autofillFocusedLabels {
  .label {
    @include i.label(focused);
  }

  .input::placeholder {
    color: transparent;
  }
}

.input {
  @include i.input;
}

.input::placeholder {
  @apply text-placeholder;
}

.withoutLabel {
  @include i.input(without-label);
}

.focusedInput:not(:disabled) {
  @include i.input(focused);
}

.errorInput:not(:disabled) {
  @include i.input(error);
}

.helperText {
  @include i.label(helper);
}

.isError {
  @include i.label(error);
}

.isDisabled {
  @include i.label(disabled);
}

@keyframes on-auto-fill-start {
  .100% {
    background: transparent;
  }
}

@keyframes on-auto-fill-cancel {
  .100% {
    background: transparent;
  }
}

.input:-webkit-autofill {
  animation-name: on-auto-fill-start;
}

.input:-webkit-autofill + label {
  @include i.label(focused);
}

.input:not(:-webkit-autofill) {
  animation-name: on-auto-fill-cancel;
}
