@use '@assets/mixins/loading-animation' as loading;

.skeleton {
  @apply block;
  @include loading.animation;
}

.wrapper {
  @apply block relative;

  &:not(.show)::before {
    content: none;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 100;

    @include loading.animation;
  }
}
