.arrowCarousal {
  @apply text-cta;
}

.linee {
  color: #c4c4c4;
}

.bodyContainer {
  @apply lg:pt-3xl lg:pb-4 pb-10 pt-6 lg:px-6 px-4 text-left relative;

  @screen md {
    padding-left: calc((100% - 700px) / 2);
    padding-right: calc((100% - 800px) / 2);
  }

  @screen lg {
    @apply flex flex-row;

    padding-left: calc((100% - 1000px) / 2);
    padding-right: calc((100% - 1000px) / 2);
  }

  @screen xl {
    padding-left: calc((100% - 1300px) / 2);
    padding-right: calc((100% - 1300px) / 2);
  }
}

.stickyContainer {
  @apply flex flex-row mb-7;

  @screen lg {
    @apply flex-col sticky self-start basis-auto left-12 top-48 mb-0 w-auto;

    margin-right: 5%;
    min-width: 250px;
  }
}

.imageContainer {
  @apply m-auto;

  max-width: 1440px;
}

.button {
  @apply overflow-hidden;

  width: 10rem;
  min-width: fit-content;
  max-width: 100%;
}

.productCard {
  max-width: 280px;
}
