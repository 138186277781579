@use './variables.module' as vars;

.navigationBar {
  @apply grid grid-cols-3 px-5;

  height: vars.$navigation-bar-height;
  max-height: vars.$navigation-bar-height;
}

.logoTile {
  img {
    height: 50px; // component height attribute was being overriden by CSS
    max-width: 70px;
  }
}

.logoLife360 {
  img {
    height: auto; // component height attribute was being overriden by CSS
    max-height: 38px;
  }
}
