.root {
  @apply h-full bg-primary mx-auto transition-colors duration-150;
}

.nav {
  @apply sticky top-0 z-40;
}

/* stylelint-disable-next-line selector-class-pattern */
:global(.mobile-menu-open) {
  .nav {
    z-index: 9999; // precedence over braze only hwne mobile menu is open
  }
}
