@use '@assets/mixins/typography';

.text {
  @include typography.text('medium');

  @screen md {
    font-size: 13px;
    line-height: 17px;
  }
}

.mobileSecondaryLink {
  @apply flex flex-row items-center space-x-2;

  .mobileSecondaryLinkIcon {
    @apply text-base lg:hidden text-inherit;
  }
}

.cart {
  @apply relative cursor-pointer;

  .cartIcon {
    @apply relative flex flex-row items-center;
  }

  .itemsCount {
    @apply absolute rounded-full flex items-center justify-center font-semibold w-4 h-4 bg-purple text-white;

    font-size: 10px;
    right: -8px;
    top: -6px;
  }
}
