.spacerRow {
  @apply col-span-2;

  grid-row-start: var(--spacer-grid-row-mobile);
  border: solid 1px transparent;

  // to simulate rounded gradient border, unable to only use border-image
  background-image: linear-gradient(white, white), linear-gradient(90.36deg, #02e8bf 23.9%, #8652ff 83.53%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 3px;

  @screen md {
    @apply col-span-3;

    grid-row-start: var(--spacer-grid-row-desktop);
  }
}
