.root {
  @apply w-64 flex flex-col justify-between;

  max-height: 80vh;
  justify-content: center;

  @screen md {
    @apply w-72;
  }
}
