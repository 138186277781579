@use '@assets/mixins/typography';

.input {
  @include typography.text('text-3');
  @apply relative block left-0 w-full bg-primary appearance-none outline-none;

  &.notEmpty {
    padding-right: 30px;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
}

.clear {
  @apply absolute inset-y-0 flex items-center justify-center z-20 text-secondary right-6;

  width: 30px;

  &.isFocused {
    @apply right-0;
  }
}
