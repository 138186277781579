@mixin overlay-arrow($overlay-width: 360px, $arrow-size: 6px) {
  @apply p-m absolute bg-primary border border-outline-primary shadow-magical z-50;

  width: 100vw;
  max-width: 100vw;
  right: 0;
  top: 41px;

  &::after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    right: #{$arrow-size * 2};
    box-sizing: border-box;
    border: $arrow-size solid transparent;
    border-color: transparent transparent var(--primary) var(--primary);
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -1px 1px 1px 0 var(--outline-primary);
  }

  @screen md {
    min-width: $overlay-width;
    width: inherit;
    right: 32px;
    top: 40px;
    border-radius: 8px;

    &::after {
      right: 0;
      border-width: 7px;
    }
  }
}
