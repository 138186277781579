.root {
  @apply flex flex-row items-center;
}

.description {
  @apply font-normal mr-3;

  color: #16012a;
}

.button {
  @apply font-normal;

  font-size: 20px;
  line-height: 22px;
}
