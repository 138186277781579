@use '@assets/mixins/overlay-top-arrow' as overlay;

$overlay-width: 360px;

.root {
  @include overlay.overlay-arrow($overlay-width);

  right: -20px;

  @screen md {
    right: -20px;
  }
}

.noArrow {
  &::after {
    content: none;
  }

  right: 0;
  top: 50px;

  @screen md {
    right: 0;
    top: 50px;
  }
}

.wrapper {
  @apply mx-auto;

  max-width: $overlay-width;

  @screen md {
    max-width: 100vw;
  }
}

.close {
  @apply absolute;

  top: 16px;
  right: 16px;
}

.button {
  @apply px-7;
}

.header {
  font-size: 20px;
}
