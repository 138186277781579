@use './variables.module' as vars;

.link {
  @apply pt-[18px] pb-[12px] font-bold text-white uppercase block w-full;

  &.sale {
    @apply rounded-full p-3 mt-3 max-w-max;

    background-color: var(--white);
    color: #0d102e;
  }
}

.dropdown {
  @apply w-full fixed left-0 right-0 overflow-hidden flex flex-col;

  top: vars.$navigation-bar-height;
  height: calc(100% - vars.$navigation-bar-height);

  .dropdownHeader {
    @apply sticky top-0 flex flex-row justify-end bg-lilac items-center pr-4 flex-shrink-0;

    height: vars.$navigation-auth-bar-height;
  }

  .dropdownScrollableArea {
    @apply flex flex-col justify-between bg-purple flex-1 overflow-y-auto overscroll-y-none;
  }

  .dropdownMainLinks {
    @apply px-4 pb-4 bg-purple;
  }

  .dropdownExtraLinks {
    min-height: 150px;

    @apply bg-eggplant text-white font-bold;
  }
}

.section {
  .subLink {
    line-height: 22.5px;

    @apply font-normal text-white;
  }

  .subLinkContainer {
    @apply border-b-[0.5px] border-violet py-3;
  }

  .iconContainer {
    @apply flex flex-row justify-between items-center w-full;
  }

  .sectionHeader {
    line-height: 16px;
  }
}
