@use '@assets/mixins/typography';

div.root {
  @apply flex flex-col items-center justify-center px-6 py-2 text-center;

  &.underlined {
    a {
      @apply underline;
    }
  }

  a {
    @apply text-inherit;

    &:hover {
      @apply underline;
    }
  }
}
