.root {
  @apply inline-flex text-center items-center leading-7;

  & span {
    @apply rounded-full;

    background-color: currentColor;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    margin: 0 2px;

    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.4s;
    }
  }

  &.small {
    & span {
      width: 0.4rem !important;
      height: 0.4rem !important;
    }
  }

  &.medium {
    & span {
      width: 0.5rem !important;
      height: 0.5rem !important;
    }
  }

  &.large {
    & span {
      width: 0.6rem !important;
      height: 0.6rem !important;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}
