.form {
  @apply w-64 flex flex-col justify-between;

  @screen md {
    @apply w-72;
  }
}

.link {
  @apply w-full text-center;
}

.linkWrapped {
  @apply text-center;
}

.linkText {
  @apply text-cta underline;

  text-decoration-color: currentColor;
}

button.button {
  @apply px-l py-0;

  min-height: 40px;
}

.tosContainer {
  @apply text-center text-xs;

  padding: 0 5px;
  margin-bottom: 10px !important;
}

.verificationContainer {
  @apply text-center;

  margin-bottom: 20px;
}

.verifiedWrapper {
  @apply flex items-center justify-center absolute;

  left: 0;
  right: 0;
  top: 20px;
}

.verifiedLabel {
  @apply rounded-full bg-green-200 px-2 py-1 leading-none flex align-middle;
}

.verifiedText {
  @apply inline-flex text-white leading-none;

  font-size: 12px;
}

.standaloneFormPage {
  @apply bg-gray-5;

  min-height: 200px;

  .formHeader {
    @apply text-center mb-8;
  }

  .formDescription {
    @apply mb-8 lg:mb-24 text-center;
  }

  .formCta {
    @apply w-full;
  }

  .formCtaSubtitle {
    @apply font-normal text-center;

    font-size: 16px;
    line-height: 20px;

    @screen md {
      font-size: 21.28px;
      line-height: 26.6px;
    }

    a {
      @apply text-cta font-bold block;
    }
  }

  .formFooter {
    @apply flex flex-col mt-16 lg:mt-20 space-y-8;

    &__noForm {
      @apply mt-13;
    }
  }
}
