@use '@assets/mixins/selected' as s;

.image {
  @apply w-full h-full object-contain;
}

.selected,
.selected[type='button'] {
  @include s.selected;
}

.display {
  @apply flex justify-center items-center box-border;

  flex-flow: column nowrap;
  border-radius: 6px;
  overflow: hidden;
  aspect-ratio: 1;
}

.option {
  position: relative;
  display: block;
  cursor: pointer;
  min-width: 80px;
  width: 80px;
  border-radius: 6px;

  @screen lg {
    overflow: hidden;
    min-width: inherit;
    width: inherit;
    height: inherit;
  }
}
