@use '@assets/mixins/typography';
@use '@assets/mixins/links-hovers' as effect;

.footer {
  @apply bg-secondary;

  padding-block: 44px;

  @screen lg {
    padding-block: 55px;
  }

  .link {
    color: var(--white);
  }

  .text {
    @apply font-normal text-center;

    font-size: 14px;
    line-height: 22px;
    color: var(--outline-primary);

    @screen md {
      @apply text-left;
    }

    a {
      @include effect.u-hover($color: var(--white));
    }
  }

  &Wrapper {
    @apply flex flex-col;

    .menuSection {
      @screen lg {
        @apply text-left;

        display: grid;
        grid-column: 2;
        grid-auto-flow: row;
        align-content: space-between;
        flex: 3;
        border-right: 1px solid var(--text-disabled);
        margin: 0;
      }

      %button-style {
        color: var(--white);
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 19px;
        letter-spacing: -0.5px;
      }

      %link-style {
        @apply text-left;
        @include typography.text('base');

        list-style: none;
        font-style: normal;
        letter-spacing: -0.5px;
        color: var(--white);
        margin-bottom: 15px;
      }

      .linkList {
        > span,
        &Title {
          @extend %button-style;
        }

        li {
          @extend %link-style;

          &:first-child {
            margin-top: 15px;
          }

          a,
          button {
            @include effect.u-hover($color: var(--white));
          }
        }

        &Container {
          display: none;

          @screen lg {
            display: grid;
            grid-auto-flow: column;
            grid-column: auto;
          }
        }

        &Accordion {
          padding: 16px 0 10px;

          > button > span {
            @extend %button-style;
          }

          li {
            @extend %link-style;

            &:first-child {
              margin-top: 15px;
            }
          }
        }
      }
    }

    .contactSection {
      .emailOptIn {
        .emailOptInInput {
          background: var(--white);
          border-radius: 6px;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 110%;
          color: var(--grey-800);
          height: 100%;

          &::placeholder {
            color: var(--grey-800);
          }
        }

        .emailOptInField {
          @apply grid;

          grid-template-columns: 220px 44px;
          grid-template-rows: 44px;
          width: 270px;
          grid-auto-flow: column;
          justify-content: space-between;

          @screen lg {
            grid-template-columns: 220px 80px;
            width: 306px;
          }
        }

        .emailOptInButton {
          background: var(--cta);
          border-radius: 6px;
          padding: 14px;
          height: 44px;

          &Icon {
            display: block;
            color: var(--white);

            @screen lg {
              display: none;
            }
          }

          &Label {
            display: none;
            color: var(--white);
            font-size: 18px;

            @screen lg {
              display: block;
            }
          }

          @screen lg {
            padding: 4px 2px;
          }
        }
      }

      .emailOptIn,
      .socialMedia,
      .downloadApp {
        padding-bottom: 26px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @screen lg {
          align-items: flex-end;
        }
      }

      .socialMediaLinksContainer {
        display: inline-flex;
        gap: 8px;

        .socialMediaItem {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;

          .socialMediaLink {
            font-size: 22px;

            .socialMediaLinkIcon {
              color: var(--white);
              transition: 0.2s linear;
            }

            &:hover {
              .socialMediaLinkIcon {
                color: var(--violet);
              }
            }
          }
        }
      }

      @screen lg {
        flex: 2;
      }
    }

    @screen lg {
      justify-content: center;
      flex-direction: row-reverse;
    }
  }
}

.heading {
  @apply border-0 uppercase;

  line-height: 16px;
  font-size: 12px;
  margin-bottom: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.5rem;
  width: 85%;
}

.separator {
  border: 1px solid var(--text-disabled);
}

.inputError {
  font-size: 14px;
  line-height: 22px;
  color: var(--danger);
}

.inputSuccess {
  font-size: 14px;
  line-height: 22px;
  color: var(--white);
}

.privacy {
  height: 25px;
  margin-left: 5px;
}
