@use '@assets/mixins/text-selector' as selector;
@use '@assets/mixins/disabled' as d;

.root {
  @apply bg-primary text-cta cursor-pointer inline-flex px-6 rounded-full font-bold text-center justify-center border border-cta items-center h-10 whitespace-pre;

  font-size: 16px;

  @include selector.all-typography {
    @apply text-cta w-full overflow-hidden overflow-ellipsis;
  }

  @screen lg {
    font-size: 19px;
  }

  &.disabled,
  &.disabled[type='button'],
  &.disabled[type='reset'],
  &.disabled[type='submit'] {
    @apply bg-disabled;
  }
}

.root:hover {
  &:not(.disabled) {
    @apply text-hover-cta border border-hover-cta;

    @include selector.all-typography {
      @apply text-hover-cta;
    }
  }
}

.root:focus {
  @apply shadow-outline-normal outline-none;
}

.slim {
  @apply py-2 transform-none normal-case;
}

.cta,
.cta[type='button'],
.cta[type='reset'],
.cta[type='submit'] {
  @apply text-white bg-cta rounded-full border-none max-w-full;

  @include selector.all-typography {
    color: var(--white);
  }
}

.cta:hover,
.cta:focus {
  &:not(.disabled) {
    @apply bg-hover-cta border-cta text-white;

    @include selector.all-typography {
      color: var(--primary);
    }
  }
}

.disabled,
.disabled[type='button'],
.disabled[type='reset'],
.disabled[type='submit'],
.disabled:hover,
.disabled:disabled {
  @apply text-secondary border-disabled cursor-not-allowed;

  filter: grayscale(1);
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;

  @include selector.all-typography {
    @apply text-secondary;
    @include d.disabled;
  }
}

.sm {
  @apply px-10;
}

.md {
  @apply px-14;
}

.lg {
  /* Likely to go full width on smaller screens */
  @apply w-full md:w-auto md:px-20;
}
