.container {
  @apply block;
}

.header {
  @apply flex justify-between items-center mb-xs w-full text-left;

  @screen md {
    @apply mb-s;
  }
}

.separator {
  @apply border-b border-outline-primary w-full;
}

.icon {
  transition: rotate 0.25s ease-out;

  &.open {
    rotate: 180deg;
  }
}
