@use '@assets/mixins/disabled' as d;
@use '@assets/mixins/typography';

$image-size: 96px;

.root {
  @apply border border-gray-20 p-2;

  border-radius: 6px;
  min-height: $image-size;
}

.upsell {
  & root {
    @apply border border-gray-20;

    border-radius: 6px;
  }

  &Img {
    width: 60px;
    height: 60px;

    &Container {
      flex: 0 0 60px;
    }
  }
}

.productImg {
  align-self: flex-start;
  width: $image-size;
  height: $image-size;
}

.content {
  @apply flex grow flex-col justify-center p-2;
}

.action {
  @apply flex justify-between items-center;
}

.quantity {
  @apply bg-primary text-center;
  @include typography.text('eyebrow');

  width: 1.8em;
  height: 24px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    @apply appearance-none m-0;

    -webkit-appearance: none;
  }

  &Wrapper {
    @apply flex border border-outline-primary overflow-hidden;

    border-radius: 4px;
  }

  &Button {
    @apply bg-gray-20;

    height: 24px;
    width: 1.5em;

    &:disabled {
      @include d.disabled(wait);
    }
  }

  -moz-appearance: textfield;
}

.disabled {
  opacity: 0.5;
}
