.badge {
  @apply font-normal inline-block leading-none;

  font-size: 12px;
  padding: 6px 4px;

  // default is mint bg with midnight text
  background-color: var(--mint);
  color: var(--midnight);
  border-radius: 3px;

  @screen lg {
    font-size: 12px;
  }
}
