.root {
  @apply flex flex-col flex-1;

  a {
    &:hover {
      .image {
        @apply scale-105;
      }

      .title {
        @apply text-purple;
      }
    }
  }
}

.image {
  transition: 0.4s ease;
}
