@use '@assets/mixins/typography';

.root {
  @apply bg-primary transition-colors duration-150 relative leading-none;

  padding-right: 5px;
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;

  &.isFocused {
    @apply pr-0;
  }
}

.input {
  @apply px-3 py-2 pl-8;

  @screen md {
    @apply border-b border-outline-primary;
  }

  @screen lg {
    width: 150px;
    padding-left: 21px;

    &::placeholder {
      @include typography.text('xsmall');

      color: #ccc;
    }
  }

  &:focus {
    @apply block left-0 absolute md:relative top-10 md:top-0 md:left-auto z-0;

    border-color: var(--text-secondary);
    width: auto;
    outline: none;
    min-width: 500px;
    max-width: 100%;
  }
}

.iconContainer {
  @apply absolute inset-0 flex w-min items-center z-10 text-secondary pl-1;

  top: -5px;

  @screen md {
    top: 0;
  }

  &.isFocused {
    @apply top-14 mt-1 left-0;

    @screen sm {
      @apply inset-y-0 mt-0;
    }
  }
}

.clearOnFocus {
  @apply right-0;
}

.searchIcon {
  font-size: 13px;
  color: #ccc;
}
