%star {
  font-size: 16px;
}

.star {
  @extend %star;

  color: transparent;
}

.filling {
  @extend %star;
  @apply absolute overflow-hidden;
}

.defaultColor {
  color: var(--black);
}
