.header {
  @apply sticky top-0 z-40 shadow-magical border-b border-grey-400 bg-primary;

  height: 56px;

  &Wrapper {
    @apply flex justify-center items-center h-full bg-primary relative;

    @screen md {
      @apply justify-between;
    }
  }
}

.logo {
  height: 40px;
  width: auto;
}
