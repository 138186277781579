.root {
  @apply bg-primary text-primary flex items-center inset-0 justify-center;

  background-color: rgba(0, 0, 0, 0.35);

  &:not(.localized) {
    @apply fixed;

    z-index: 9999;
  }

  &.localized {
    @apply absolute;
  }
}

.modal {
  @apply bg-primary py-3xl px-xxl border border-accents-2 relative;

  max-width: calc(100% - 36px);
  max-height: 100vh;
  border-radius: 16px;

  @screen lg {
    @apply p-12;
  }
}

.modal:focus {
  @apply outline-none;
}

.content {
  max-height: calc(100vh - (2 * #{theme('spacing.3xl')}));
  overflow-y: auto;
}
