.container {
  @apply relative;

  height: fit-content;
}

@mixin badge {
  @apply absolute z-10;
}

.topRight {
  @apply top-0 right-0;
  @include badge;
}

.topLeft {
  @apply top-0 left-0;
  @include badge;
}

.bottomRight {
  @apply bottom-0 right-0;
  @include badge;
}

.bottomLeft {
  @apply bottom-0 left-0;
  @include badge;
}
